import { FiArrowUpCircle, FiPlusCircle } from "react-icons/fi";
import { TbFileExport } from "react-icons/tb";
import { RiBroadcastLine } from "react-icons/ri";
import { useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
  duration: 800,
});

interface Props {
  position: string;
  label: string;
  onClick: () => void;
}

export const FloatButton: React.FC<Props> = ({ position, label, onClick }) => {
  const [showText, setShowText] = useState(false);
  return (
    <div
      onMouseEnter={() => setShowText(true)}
      onMouseLeave={() => setShowText(false)}
      onClick={() => onClick()}
      className={`${position} flex items-center space-x-2 bg-white rounded-full hover:rounded-lg p-1 hover:px-2 hover:py-1 cursor-pointer group z-30 transition-all duration-100`}
    >
      {(() => {
        switch (label) {
          case "Broadcast":
            return (
              <RiBroadcastLine
                data-aos="zoom-in"
                className="text-yellow-600 text-2xl"
              />
            );
          case "Export":
            return (
              <TbFileExport
                data-aos="zoom-in"
                className="text-green-700 text-2xl"
              />
            );
          case "Register":
          case "Add Template":
          case "Add User":
          case "Add Broadcast":
            return (
              <FiPlusCircle
                data-aos="zoom-in"
                className="text-purple-600 text-2xl"
              />
            );
          case "Upload":
            return (
              <FiArrowUpCircle
                data-aos="zoom-in"
                className="text-sky-400 text-2xl"
              />
            );
        }
      })()}
      {showText && <p className="text-black text-sm">{label}</p>}
    </div>
  );
};
