import { SiHappycow } from "react-icons/si";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

export const Logo = (props: { onClick: () => void }) => {
  return (
    <div
      data-aos="zoom-in"
      onClick={() => props.onClick()}
      className="flex items-center font-bold text-2xl cursor-pointer"
    >
      <SiHappycow className="h-9 w-9" />
      <h2 className="pl-3">
        <span className="text-purple-600 mr-1">Mpatlise</span>Matimela
      </h2>
    </div>
  );
};
