import { useEffect } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.min.css";

interface Props {
  className?: string;
  required?: boolean;
  label: string;
  placeholder?: string;
  value?: string;
  onChange: (e: string) => void;
}

export const TimeInput: React.FC<Props> = ({
  className,
  required,
  label,
  placeholder,
  value,
  onChange,
}) => {
  useEffect(() => {
    onChange(value);
    // eslint-disable-next-line
  }, []);
  return (
    <div className={`flex flex-col mb-4 ${className}`}>
      <label className="text-sm text-gray-500 mb-1 flex justify-start">
        {label}
        {required ? <p className="text-red-600">&nbsp;*</p> : null}
      </label>
      <Flatpickr
        className="border text-sm rounded-md py-2 px-4 w-full focus:outline-none focus:shadow-lg focus:border-purple-600 transition duration-200"
        onChange={(date: any) => onChange(date)}
        value={value}
        required={required}
        placeholder={placeholder}
        options={{
          enableTime: true,
          noCalendar: true,
          dateFormat: "H:i",
        }}
      />
    </div>
  );
};
