import { Combobox, Transition } from "@headlessui/react";
import _ from "lodash";
import { Fragment, useEffect, useRef } from "react";
import { HiSelector, HiCheck } from "react-icons/hi";
import { Chip } from "./Chip";

interface Props {
  className?: string;
  selected?: { value: string; custom: string[] };
  label?: string;
  onChange: (arg: { value: string; custom: string[] }) => void;
}

export const SelectBroadcastTo: React.FC<Props> = ({
  className,
  label,
  selected,
  onChange,
}) => {
  const options = ["all", "farmers", "users","zones","others"];
  const ref = useRef(null);
  useEffect(() => {
    selected ??
      onChange({
        value: "all",
        custom: [""],
      });
    // eslint-disable-next-line
  }, []);
  const handleInput = (event) => {
    if (
      event.target.value.length &&
      [",", ".", ";", "\t", "\n", "|"].includes(
        event.target.value[event.target.value.length - 1]
      )
    ) {
      event.target.value.trim().length > 1 &&
        onChange({
          ...selected,
          custom: [...selected?.custom, ""],
        });
    }

    if (
      ["-", "+", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(
        event.target.value[event.target.value.length - 1]
      ) ||
      !event.target.value
    )
      onChange({
        ...selected,
        custom: [
          ...selected?.custom.filter(
            (input, i) => selected?.custom.length - 1 !== i
          ),
          event.target.value,
        ],
      });
  };
  return (
    <div className={`w-full ${className}`}>
      <label className="text-sm text-gray-500 flex justify-start">
        {label}
      </label>
      <Combobox
        value={selected?.value}
        onChange={(e) => onChange({ ...selected, value: e })}
      >
        <div className="relative mt-1">
          <div className="relative w-full cursor-default bg-white text-left ">
            <div
              className="focus-within:shadow-lg border rounded-md focus-within:border-purple-600 transition duration-200 flex overflow-hidden"
              onClick={() => ref.current?.focus()}
            >
              <div
                className={`flex hideScroll items-center w-[200%] max-w-fit space-x-1 overflow-x-auto ${
                  (selected?.value !== "others" || selected?.custom.length) &&
                  "pl-2"
                }`}
              >
                {selected?.value !== "others" && (
                  <Chip
                    label={_.startCase(selected.value)}
                    onClose={() => onChange({ ...selected, value: "others" })}
                  />
                )}
                {selected?.custom?.map((item, index) => {
                  if (selected?.custom.length - 1 === index) return null;
                  return (
                    <Chip
                      key={index}
                      label={item}
                      onClose={() => {
                        onChange({
                          ...selected,
                          custom: selected.custom.filter(
                            (input, i) => index !== i
                          ),
                        });
                      }}
                    />
                  );
                })}
              </div>
              <input
                ref={ref}
                value={selected?.custom[selected?.custom.length - 1]}
                className="appearance-none w-full rounded-md py-2 px-3 focus:outline-none"
                onChange={handleInput}
                placeholder="Select or Enter the Input"
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <HiSelector
                  className="h-5 w-5 text-[#6e6b7bbc]"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">
              {options.map((option, key) => (
                <Combobox.Option
                  key={key}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pr-10 pl-4 ${
                      active
                        ? "bg-[#f4e7ff74] text-purple-600"
                        : "text-gray-900"
                    }`
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-semibold" : "font-normal"
                        }`}
                      >
                        {_.startCase(option)}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-purple-600">
                          <HiCheck className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};
