interface Props {
  separator: string;
  onChange: (e: string) => void;
}

export const SelectSeparator: React.FC<Props> = ({ separator, onChange }) => {
  return (
    <div className="w-full overflow-hidden grid grid-cols-6 sm:grid-cols-5 border border-purple-600 text-purple-600 [&>*]:text-center [&>*]:p-1 hover:cursor-pointer rounded-md mt-2 text-sm">
      <span
        onClick={() => onChange(",")}
        className={`border-r border-purple-600 col-span-2 sm:col-span-1 ${
          separator === "," ? "bg-purple-100" : "hover:bg-purple-50"
        }`}
      >
        , (Comma)
      </span>
      <span
        onClick={() => onChange(";")}
        className={`border-r border-purple-600  col-span-2 sm:col-span-1 ${
          separator === ";" ? "bg-purple-100" : "hover:bg-purple-50"
        }`}
      >
        ; (Semicolon)
      </span>
      <span
        onClick={() => onChange("|")}
        className={`sm:border-r border-purple-600 col-span-2 sm:col-span-1 ${
          separator === "|" ? "bg-purple-100" : "hover:bg-purple-50"
        }`}
      >
        | (Bar)
      </span>
      <span
        onClick={() => onChange("\t")}
        className={`border-r border-t sm:border-t-0 border-purple-600 col-span-3 sm:col-span-1 ${
          separator === "\t" ? "bg-purple-100" : "hover:bg-purple-50"
        }`}
      >
        Tab
      </span>
      <span
        onClick={() => onChange("\n")}
        className={`border-t border-purple-600 sm:border-t-0 col-span-3 sm:col-span-1 ${
          separator === "\n" ? "bg-purple-100" : "hover:bg-purple-50"
        }`}
      >
        New line
      </span>
    </div>
  );
};
