import { useEffect, useState } from "react";
import { BsArrowLeftCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

export const AccessDenied = () => {
  const [countdown, setCountDown] = useState(3),
    navigate = useNavigate();
  useEffect(() => {
    if (countdown === 0) return;
    let interval = setInterval(async () => {
      if (countdown === 0) clearInterval(interval);
      else setCountDown(countdown - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [countdown]);
  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center">
      {countdown > 0 ? (
        <>
          <h1 className="text-purple-600 text-3xl uppercase mb-3">
            Gently blow on the screen
          </h1>
          <div className="px-3 py-1.5 text-white rounded-full bg-purple-600">
            {countdown}
          </div>
        </>
      ) : (
        <>
          <img
            className="h-64 mb-4 rounded-3xl"
            src={
              "https://media.tenor.com/jFeOISWt0K0AAAAC/good-hair-day-cow.gif"
            }
            alt="loading..."
          />
          <h1 className="text-purple-600 text-xl font-bold">Access Denied</h1>
          <div
            onClick={() => navigate("/login")}
            className={` flex items-center space-x-2 bg-white rounded-full p-1 hover:-translate-y-[1px] cursor-pointer group z-30 transition-all duration-100`}
          >
            <BsArrowLeftCircle
              data-aos="zoom-in"
              className="text-purple-600 text-2xl"
            />
          </div>
        </>
      )}
    </div>
  );
};
