import { GrCircleQuestion } from "react-icons/gr";

export const HelpText = (props: { helpText: string }) => {
  return (
    <div className="absolute flex top-5 right-5 group cursor-pointer">
      <GrCircleQuestion className="text-xl w-72 self-end -mr-32" />
      <div className="invisible absolute whitespace-pre-wrap group-hover:visible transition-all duration-300 opacity-0 -ml-24 bg-black text-white group-hover:opacity-80 p-5 text-xs rounded-md -top-2 z-50">
        {props.helpText}
      </div>
    </div>
  );
};
