import { ReactNode, useEffect, useState } from "react";
import { RiUserSettingsLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { UserData } from "../../types";
import { handleLogout } from "../../utils";
import { Logo } from "../items/Logo";
import { ProfilePic } from "../items/ProfilePic";
import { SearchBox } from "../items/SearchBox";

interface Props {
  children: ReactNode;
  showSearch: boolean;
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
}

const StorageLayout: React.FC<Props> = ({
  setSearch,
  children,
  showSearch,
}) => {
  const navigate = useNavigate();
  const [userData] = useState<UserData>(
    JSON.parse(localStorage.getItem("userData") as string)
  );

  useEffect(() => {
    if (!userData || (!localStorage.getItem("token") as boolean)) navigate("/");
    if (userData?.exp > Date.now()) handleLogout();
  }, [userData, navigate]);

  return (
    <div className="min-h-screen divide-y">
      <div className="w-full justify-between flex px-5 py-4">
        <Logo onClick={() => navigate("/storage")} />
        {showSearch && (
          <div className="w-[40%] -translate-x-16">
            <SearchBox onChange={(e) => setSearch(e)} />
          </div>
        )}
        <div className="flex items-center space-x-3">
          <RiUserSettingsLine
            className="text-purple-600 text-2xl hover:text-[26px] hover:translate-x-[1px] cursor-pointer transition-all duration-300"
            direction="User Settings"
            onClick={() => navigate("/users")}
          />
          <ProfilePic userData={userData} />
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default StorageLayout;
