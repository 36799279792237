import React, { useEffect } from "react";

import { BsArrowRightSquare, BsArrowLeftSquare } from "react-icons/bs";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";

export function Pagination({
	setCurrentPage,
	totalPages,
	limit = 5,
}: {
	startOffset?: number;
	endOffset?: number;
	pagesCenter?: number;
	breaker?: string;
	setCurrentPage: (page: number) => void;
	limit?: number;
	totalPages: number;
}) {
	const lastPages = Array.from({ length: limit }).map(
		(_, i) => totalPages - limit + i + 1
	);
	const firstPages = Array.from({ length: limit }).map((_, i) => i + 1);
	const [currentBetween, setCurrentBetween] = React.useState<number>(0);
	const debounceTimeoutRef = React.useRef<number | null>(null);
	const [currentPages, setCurrentPages] = React.useState<number[]>(
		Array.from(
			{ length: totalPages > limit ? limit : totalPages },
			(_, i) => i + 1
		)
	);
	const [active, setActive] = React.useState(1);

	React.useEffect(() => {
		setActive(currentPages[currentBetween]);
	}, [currentBetween, currentPages]);

	const next = () => {
		if (currentPages[limit - 1] === totalPages) return;
		setCurrentPages((prevPages) => {
			const newPages = [
				...prevPages.slice(1),
				prevPages[prevPages.length - 1] + 1,
			];
			return newPages;
		});
	};

	const prev = () => {
		if (currentPages[0] === 1) return;
		if (active === 1) return;
		setCurrentPages((prevPages) => {
			const newPages = [prevPages[0] - 1, ...prevPages.slice(0, -1)];
			return newPages;
		});
	};

	useEffect(() => {
		if (debounceTimeoutRef.current) {
			clearTimeout(debounceTimeoutRef.current);
		}

		debounceTimeoutRef.current = window.setTimeout(() => {
			setCurrentPage(active);
		}, 300);

		return () => {
			if (debounceTimeoutRef.current) {
				clearTimeout(debounceTimeoutRef.current);
			}
		};
	}, [active, setCurrentPage]);

	return (
		<div className=" fixed bottom-0 left-[6rem] bg-white w-[calc(100vw-12rem)] justify-center flex items-center gap-8 py-4 bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-30">
			<button
				className=" text-[2rem] outline-none transition-all rounded-md duration-75 hover:scale-110 active:scale-105 hover:bg-purple-300 active:bg-ptrple-400 disabled:opacity-50 disabled:cursor-not-allowed text-white bg-purple-600"
				disabled={currentPages[0] === 1}
				onClick={setCurrentPages.bind(null, firstPages)}
			>
				<RxDoubleArrowLeft />
			</button>
			<button
				className=" text-4xl outline-none transition-all rounded-md duration-75 hover:scale-110 active:scale-105 hover:bg-purple-300 active:bg-ptrple-400 disabled:opacity-50 disabled:cursor-not-allowed text-white bg-purple-600"
				disabled={currentPages[0] === 1}
				onClick={prev}
			>
				<BsArrowLeftSquare />
			</button>

			{/* <p color="gray" className="font-normal">
				Page <strong className="text-gray-900">{active}</strong> of{" "}
				<strong className="text-gray-900">{totalPages}</strong>
			</p> */}
			<div className=" flex gap-2 items-center">
				{currentPages?.map((page, index) => {
					return (
						<div
							key={index}
							className={` flex justify-center items-center h-4 w-4 p-5 rounded-full hover:bg-purple-500 hover:text-white active:bg-purple-600 ${
								index === currentBetween && "bg-purple-600 text-white"
							} cursor-pointer `}
							onClick={() => setCurrentBetween(index)}
						>
							{page}
						</div>
					);
				})}
			</div>
			<button
				className=" text-4xl outline-none transition-all duration-75 rounded-md hover:scale-110 active:scale-105 hover:bg-purple-300 active:bg-purple-400 disabled:opacity-50 disabled:cursor-not-allowed text-white bg-purple-600"
				disabled={currentPages[limit - 1] === totalPages}
				onClick={next}
			>
				<BsArrowRightSquare />
			</button>
			<button
				className=" text-[2rem] outline-none transition-all duration-75 rounded-md hover:scale-110 active:scale-105 hover:bg-purple-300 active:bg-purple-400 disabled:opacity-50 disabled:cursor-not-allowed text-white bg-purple-600"
				disabled={currentPages[limit - 1] === totalPages}
				onClick={setCurrentPages.bind(null, lastPages)}
			>
				<RxDoubleArrowRight />
			</button>
		</div>
	);
}
