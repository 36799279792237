import { useEffect, useRef, useState } from "react";
import { TableBuilder } from "../components/items/TableBuilder";
import StorageLayout from "../components/layout/StorageLayout";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import { FloatButton } from "../components/items/FloatButton";
import { exportTable, getBroadcast } from "../utils";
import LoadingBar from "react-top-loading-bar";
AOS.init();

export const Broadcast = () => {
  const [search, setSearch] = useState(""),
    [data, setData] = useState<any>({}),
    [loading, setLoading] = useState(true),
    navigate = useNavigate(),
    progressRef = useRef<any>(null);

  useEffect(() => {
    setLoading(true);
    setData(null);
    (async () => {
      let tempData = await getBroadcast(1, search);
      setData(tempData);
      setLoading(false);
    })();
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    (async () => {
      progressRef.current.continuousStart();
      let tempData = await getBroadcast();
      setData(tempData);
      setLoading(false);
      progressRef.current.complete();
    })();
  }, []);

  return (
    <StorageLayout showSearch={true} setSearch={setSearch}>
      <LoadingBar
        color="rgb(147 51 234)"
        height={2}
        ref={progressRef}
        waitingTime={200}
      />
      <div className="min-h-[calc(100vh-80px)] bg-gray-100 px-5">
        <div data-aos="zoom-in" className="flex pt-4 -mb-2">
          <h2 className="text-3xl font-bold">Broadcasting</h2>
          <FloatButton
            position="absolute right-0 top-5"
            label="Add Broadcast"
            onClick={() => navigate("/add_broadcast")}
          />
        </div>
        {!loading ? (
          <TableBuilder
            table="broadcast"
            incoming_data={data}
            headings={[
              "name",
              "campaign_id",
              "contacts",
              "campaign_type",
              "status",
            ]}
          />
        ) : (
          <div
            data-aos="zoom-in"
            className="mx-auto w-fit text-xl font-bold mt-4"
          >
            <img
              className="h-24 rounded-3xl"
              src={
                "https://media.tenor.com/QXPDk3LKgWQAAAAi/cow-dancing-cow.gif"
              }
              alt="loading..."
            />
          </div>
        )}
        <FloatButton
          position="fixed right-5 bottom-5"
          label="Export"
          onClick={() => exportTable("broadcast")}
        />
      </div>
    </StorageLayout>
  );
};
