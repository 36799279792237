import { Switch } from "@headlessui/react";
import AOS from "aos";
import "aos/dist/aos.css";
import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
	deleteBrand,
	getNextSchedule,
	loadMoreData,
	updateFarmer,
	updateUser,
} from "../../utils";
import { Brands } from "./Brands";
import { DateInput } from "./DateInput";
import { InputBox } from "./InputBox";
import { SelectStatus } from "./SelectStatus";
import { StatusChip } from "./StatusChip";
import { TableSubtext } from "./TableSubtext";
import { TypeChip } from "./TypeChip";
import { Zones } from "./Zones";
import relativeTime from "dayjs/plugin/relativeTime";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { table } from "console";

import { Pagination } from "./Pagination";
dayjs.extend(LocalizedFormat);
dayjs.extend(relativeTime);

AOS.init();

interface Props {
	table?: string;
	headings: string[];
	incoming_data: any;
}

export const TableBuilder: React.FC<Props> = ({
	table,
	headings,
	incoming_data,
}) => {
	const [selected, setSelected] = useState<any>(null),
		[hoverRow, setHover] = useState<any>(null),
		[broadcastEnabled, setBroadcastEnabled] = useState(false),
		[subscriptionData, setSubscriptionData] = useState({
			subscription_confirmation: false,
			subscription_fee: "",
			subscription_due_date: "",
			status: "",
			brand: [""],
			zone: [""],
		}),
		[data, setData] = useState(incoming_data?.[table + "s"]),
		[page, setPage] = useState(1);

	const handleSave = (e: any) => {
		e.preventDefault();
		toast.promise(
			updateFarmer({
				...selected,
				...subscriptionData,
				broadcast_message: broadcastEnabled,
				subscription_fee: parseFloat(subscriptionData?.subscription_fee),
			}),
			{
				loading: "Updating...",
				success: "Farmer data updated sucessfully",
				error: (err) => err.message || "An unexpected error occurred",
			}
		);
		const newDate = data.filter((item) => item !== selected);
		setData([
			...newDate,
			{
				...selected,
				...subscriptionData,
				broadcast_message: broadcastEnabled,
			},
		]);
		setSelected(null);
		setSubscriptionData({
			subscription_confirmation: false,
			subscription_fee: "",
			subscription_due_date: "",
			status: "",
			brand: [""],
			zone: [""],
		});
		setBroadcastEnabled(false);
	};

	const handleClick = (e: any) => {
		if (
			![
				"TD",
				"LABEL",
				"INPUT",
				"BUTTON",
				"svg",
				"SELECT",
				"SPAN",
				"FORM",
				"LI",
				"path",
				"line",
			].includes(e.target.nodeName)
		)
			setSelected(null);
	};

	// const loadMore = () => {
	// 	if (
	// 		window.innerHeight + document.documentElement.scrollTop ===
	// 			document.scrollingElement.scrollHeight &&
	// 		incoming_data?.pagination?.total_page > page
	// 	) {
	// 		setPage((prev) => {
	// 			return prev + 1;
	// 		});
	// 	}
	// };

	useEffect(() => {
		(async () => {
			const data = await loadMoreData(
				table,
				page,
				incoming_data?.pagination?.query
			);
			if (!data?.[table !== "farmer" ? table : table + "s"]) {
				setData([]);
				return;
			}
			if (data !== data?.[table !== "farmer" ? table : table + "s"])
				setData(() => {
					return data?.[table !== "farmer" ? table : table + "s"];
				});
		})();
		// eslint-disable-next-line
	}, [page]);

	// useEffect(() => {
	// 	document.addEventListener("click", handleClick, true);
	// 	document.addEventListener("scroll", loadMore);
	// 	return () => {
	// 		document.removeEventListener("click", handleClick, true);
	// 		document.removeEventListener("scroll", loadMore);
	// 	};
	// 	// eslint-disable-next-line
	// }, []);

	useEffect(() => {
		if (subscriptionData.subscription_confirmation === null) return;
		if (subscriptionData.subscription_confirmation)
			setSubscriptionData((prev) => {
				return { ...prev, status: "active" };
			});
		else
			setSubscriptionData((prev) => {
				return { ...prev, status: "pending" };
			});
	}, [subscriptionData.subscription_confirmation]);

	useEffect(() => {
		setData(incoming_data?.[table !== "farmer" ? table : table + "s"]);
	}, [incoming_data, table]);

	const getBrands = (brands: string[]) => {
		let result = "";
		for (let i = 0; i < brands?.length; i++) {
			if (i < 3 && i !== brands?.length - 1) result += brands[i] + ", ";
			if (i < 4 && i === brands?.length - 1) result += brands[i];
		}
		if (brands?.length > 4) result += `... (${brands?.length})`;
		return result;
	};

	const getValues = (heading: string, item: any) => {
		if (
			(item[heading] && heading.includes("_at")) ||
			heading.includes("_date") ||
			heading.includes("_time")
		)
			return item[heading]
				? dayjs(item[heading]).format("DD/MM/YYYY HH:mm")
				: "--";
		else
			switch (heading) {
				case "name":
					if (table === "broadcast")
						return (
							<>
								<p className="text-[15px]">{item["campaign_name"]}</p>
								{item?.created_at && (
									<TableSubtext
										text={`Created ${dayjs(item.created_at).fromNow()}`}
									/>
								)}
							</>
						);
					else return item[heading] || "--";
				case "subscription_fee":
					return item[heading] ? "P " + item[heading] : "--";
				case "brand":
					return getBrands(item[heading]);
				case "status":
					if (table === "users") {
						return (
							<SelectStatus
								className="z-10 -mt-2"
								disabled={item.id === null || item.id === undefined}
								onChange={(e) => {
									if (
										(item.is_active && e === "active") ||
										(!item.is_active && e === "deactivated")
									)
										return;
									toast.promise(
										updateUser({ ...item, is_active: e === "active" }),
										{
											loading: "Updating...",
											success: "User updated sucessfully",
											error: (err) =>
												err.message || "An unexpected error occurred",
										}
									);
								}}
								value={item.is_active ? "active" : "deactivated"}
								items={["active", "deactivated"]}
							/>
						);
					} else
						return (
							<>
								<StatusChip label={item[heading]} />
								{item[heading] === "delivered" && (
									<TableSubtext
										text={`Delivered at: ${dayjs(item.delivered_at).format(
											"lll"
										)}`}
									/>
								)}
							</>
						);
				case "campaign_type":
					if (parseInt(item?.frequency))
						return (
							<>
								<TypeChip label={"recurring"} />
								<TableSubtext
									text={item.frequency + " " + _.startCase(item.frequency_type)}
								/>
								{item.status !== "delivered" && (
									<TableSubtext
										text={`Next Schedule Time: ${getNextSchedule(
											item.scheduled_for,
											item.frequency,
											item.frequency_type
										)}`}
									/>
								)}
								<TableSubtext
									text={`End Time: ${dayjs(item.end_at).format("lll")}`}
								/>
							</>
						);
					else
						return (
							<>
								<TypeChip label={item[heading]} />
								{item[heading] === "scheduled" && (
									<TableSubtext text={dayjs(item.scheduled_on).format("lll")} />
								)}
							</>
						);
				default:
					return item[heading] || "--";
			}
	};

	return (
		<>
			{!data?.length ? (
				<div className="mx-auto w-fit text-xl font-bold mt-4">No Data</div>
			) : (
				<div className="  flex justify-center items-center flex-col gap-8 pb-20">
					<table className="w-full mt-3">
						<thead data-aos="zoom-in">
							<tr className="[&>*]:text-start sticky top-5 [&>*]:p-2 [&>*]:pb-1.5">
								{headings.map((heading, key) => (
									<th key={key}>
										{heading === "brand"
											? "Brand #"
											: heading === "created_at"
											? "Registered At"
											: _.startCase(heading)}
									</th>
								))}
							</tr>
						</thead>
						<tbody className="text-sm">
							{data?.map((item, key) => {
								return (
									<>
										<tr
											id={key.toString()}
											data-aos={table !== "users" && "zoom-in"}
											key={key}
											onClick={() => {
												table === "farmer"
													? setSelected(item)
													: setSelected(null);
												setSubscriptionData({
													subscription_confirmation:
														item?.subscription_confirmation,
													subscription_fee: item?.subscription_fee,
													subscription_due_date: item?.subscription_due_date,
													status: item?.status,
													brand: item?.brand,
													zone: item?.zone,
												});
												setBroadcastEnabled(item?.broadcast_message);
											}}
											className={`[&>*]:py-1.5 [&>*]:pl-2 rounded-lg group cursor-pointer ${
												selected !== item
													? "shadow-sm hover:shadow-md"
													: "border-b"
											}`}
										>
											{headings.map((heading, key) => (
												<td
													className={`${
														key === 0 &&
														(selected === item && table === "farmer"
															? " rounded-tl-lg border-b"
															: "rounded-l-lg")
													} bg-white whitespace-pre-wrap ${
														selected === item &&
														table === "farmer" &&
														"border-b"
													}`}
													key={key}
												>
													{getValues(heading, item)}
												</td>
											))}
											<td
												className={`${
													selected === item && table === "farmer"
														? "rounded-tr-lg border-b"
														: "rounded-r-lg"
												} bg-white ${
													selected === item && table === "farmer" && "border-b"
												}`}
											>
												{table === "farmer" && item?.total_alerts_inbox ? (
													<div
														onMouseEnter={() => setHover(item)}
														onMouseLeave={() => setHover(null)}
														style={{ lineHeight: "16px" }}
														className="text-[10px] mr-2 flex items-center justify-center px-1 w-4 h-4 rounded-full bg-red-600 text-white relative"
													>
														{item?.total_alerts_inbox}
														<div
															className={`absolute transition-all duration-300 opacity-0 bg-black text-white group-hover:opacity-80 p-3 text-xs rounded-md -bottom-3 right-4 z-50 ${
																hoverRow === item ? "visible" : "invisible"
															}`}
														>
															{Object.keys(item?.alerts_inbox).map(
																(brand, key) => {
																	return (
																		<span
																			key={key}
																			className="flex whitespace-nowrap"
																		>
																			{brand +
																				" : " +
																				item?.alerts_inbox[brand]}
																		</span>
																	);
																}
															)}
														</div>
													</div>
												) : table === "alerts" ? (
													<div
														className={`w-2 h-2 mr-1 rounded-full ${
															item?.notification_received_by_farmer
																? "bg-green-600"
																: "bg-red-600"
														}`}
													/>
												) : null}
											</td>
										</tr>
										{selected === item && table === "farmer" && (
											<tr
												id={key.toString() + "#"}
												key={key + "#"}
												data-aos="fade-in"
												className="w-full bg-white"
											>
												<td colSpan={10} className="rounded-b-lg">
													<form
														onSubmit={handleSave}
														className="flex rounded-b-lg px-3 py-2 shadow-md space-x-4 justify-between"
													>
														<Brands
															brands={subscriptionData?.brand}
															onChange={(e) => {
																setSubscriptionData((prev) => {
																	return { ...prev, brand: e };
																});
																toast.promise(
																	updateFarmer({
																		...selected,
																		brand: e,
																	}),
																	{
																		loading: "Adding...",
																		success: "Brand added sucessfully",
																		error: (err) =>
																			err.message ||
																			"An unexpected error occurred",
																	}
																);
																const newDate = data.filter(
																	(item) => item !== selected
																);
																setData([
																	...newDate,
																	{
																		...selected,
																		brand: e,
																	},
																]);
															}}
															onClose={(e) => {
																setSubscriptionData((prev) => {
																	return {
																		...prev,
																		brand: subscriptionData?.brand?.filter(
																			(b) => b !== e
																		),
																	};
																});
																toast.promise(deleteBrand(item?.id, e), {
																	loading: "Deleting...",
																	success: "Brand deleted sucessfully",
																	error: (err) =>
																		err.message ||
																		"An unexpected error occurred",
																});
																const newDate = data.filter(
																	(item) => item !== selected
																);
																setData([
																	...newDate,
																	{
																		...selected,
																		brand: subscriptionData?.brand?.filter(
																			(b) => b !== e
																		),
																	},
																]);
															}}
														/>
														<Zones
															zones={subscriptionData?.zone}
															onChange={(e) => {
																setSubscriptionData((prev) => {
																	return { ...prev, zone: e };
																});
																toast.promise(
																	updateFarmer({
																		...selected,
																		zone: e,
																	}),
																	{
																		loading: "Adding...",
																		success: "Zone added sucessfully",
																		error: (err) =>
																			err.message ||
																			"An unexpected error occurred",
																	}
																);
																const newDate = data.filter(
																	(item) => item !== selected
																);
																setData([
																	...newDate,
																	{
																		...selected,
																		zone: e,
																	},
																]);
															}}
															onClose={(e) => {
																setSubscriptionData((prev) => {
																	return {
																		...prev,
																		zone: subscriptionData?.zone?.filter(
																			(b) => b !== e
																		),
																	};
																});
															}}
														/>
														<InputBox
															className="w-1/4"
															label="Subscription Amount"
															value={
																subscriptionData?.subscription_fee?.length
																	? subscriptionData.subscription_fee
																	: selected.subscription_fee
															}
															onChange={(e) =>
																setSubscriptionData((prev) => {
																	return { ...prev, subscription_fee: e };
																})
															}
														/>
														<DateInput
															className="w-1/4"
															label="Subscription Due Date"
															value={
																subscriptionData?.subscription_due_date?.length
																	? subscriptionData.subscription_due_date
																	: selected.subscription_due_date
															}
															onChange={(e) =>
																setSubscriptionData((prev) => {
																	return {
																		...prev,
																		subscription_due_date:
																			dayjs(e).toISOString(),
																	};
																})
															}
														/>
														<div className="flex flex-col items-center">
															<label className="text-sm text-gray-500 mb-3 flex -ml-1">
																Paid
															</label>
															<Switch
																checked={
																	subscriptionData.subscription_confirmation
																}
																onChange={(e) =>
																	setSubscriptionData((prev) => {
																		return {
																			...prev,
																			subscription_confirmation: e,
																		};
																	})
																}
																className={`${
																	subscriptionData.subscription_confirmation
																		? "bg-green-600"
																		: "bg-red-600"
																} relative inline-flex h-[24px] w-[60px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
															>
																<span
																	aria-hidden="true"
																	className={`${
																		subscriptionData.subscription_confirmation
																			? "translate-x-9"
																			: "translate-x-0.5"
																	} pointer-events-none inline-block h-[18px] w-[18px] mt-[1px] transform rounded-full bg-white shadow-xl ring-0 transition duration-200 ease-in-out`}
																/>
															</Switch>
														</div>
														<div className="flex flex-col items-center">
															<label className="text-sm text-gray-500 mb-3 flex -ml-1">
																Broadcast
															</label>
															<Switch
																checked={
																	broadcastEnabled ??
																	selected?.broadcast_message
																}
																onChange={setBroadcastEnabled}
																className={`${
																	broadcastEnabled
																		? "bg-purple-600"
																		: "bg-gray-200"
																} relative inline-flex h-[24px] w-[60px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
															>
																<span
																	aria-hidden="true"
																	className={`${
																		broadcastEnabled
																			? "translate-x-9 bg-white"
																			: "translate-x-0.5 bg-gray-400"
																	} pointer-events-none inline-block h-[18px] w-[18px] mt-[1px] transform rounded-full  shadow-lg ring-0 transition duration-200 ease-in-out`}
																/>
															</Switch>
														</div>
														<SelectStatus
															className=" min-w-[130px] z-30"
															onChange={(e) =>
																setSubscriptionData((prev) => {
																	return { ...prev, status: e };
																})
															}
															label="Status"
															value={subscriptionData?.status}
															items={["active", "pending", "deleted"]}
														/>
														<button className="px-5 py-2 h-fit self-end ml-auto bg-purple-600 rounded-lg font-semibold text-white transition-all duration-300 hover:-translate-y-0.5 hover:shadow-lg">
															Save
														</button>
													</form>
												</td>
											</tr>
										)}
									</>
								);
							})}
						</tbody>
					</table>
				</div>
			)}
			<div>
				<Pagination
					setCurrentPage={(page: number) => setPage(page)}
					totalPages={incoming_data.pagination.total_page || 10}
				/>
			</div>
		</>
	);
};
