import { useState } from "react";
import { BsPlusSquareFill } from "react-icons/bs";
import { FiX } from "react-icons/fi";
import { Button } from "./Button";
import { InputBox } from "./InputBox";

interface Props {
	zones: string[];
	onChange: (e: any) => void;
	onClose: (e: string) => void;
}

export const Zones: React.FC<Props> = ({ zones, onChange, onClose }) => {
	const [showInput, setShowInput] = useState(false),
		[input, setInput] = useState("");

	return (
		<div className="flex flex-col max-w-[25%]">
			<label className="text-sm text-gray-500 mb-1 flex justify-start">
				Zones
			</label>
			{showInput ? (
				<div className="flex space-x-1">
					<InputBox
						className="-mt-1"
						value={input}
						onChange={(e) => setInput(e)}
					/>
					<Button
						type="button"
						label="Add Zone"
						onClick={() => {
							onChange(zones ? [...zones, input] : [input]);
							setShowInput(false);
							setInput("");
						}}
					/>
				</div>
			) : (
				<div className="flex flex-wrap items-center min-h-[38px]">
					{zones?.map((zone, key) => {
						return (
							<div
								className="pl-2 pr-1.5 py-0.5 bg-purple-600 text-white mr-1 mb-1 rounded-md flex items-center text-sm cursor-default min-w-fit"
								key={key}
							>
								{zone}
								<span
									onClick={() => onClose(zone)}
									className="ml-1 hover:cursor-pointer"
								>
									<FiX />
								</span>
							</div>
						);
					})}
					<BsPlusSquareFill
						onClick={() => setShowInput(true)}
						className="text-[22px] text-purple-600 hover:text-purple-100 hover:p-[1px] cursor-pointer mb-1 hover:bg-purple-600 rounded"
					/>
				</div>
			)}
		</div>
	);
};
