import { useEffect, useState } from "react";

interface Props {
  className?: string;
  label: string;
  value: { day: number; week: number; month: number };
  required?: boolean;
  onChange: (arg: { day: number; week: number; month: number }) => void;
}

export const DurationInput: React.FC<Props> = ({
  className,
  label,
  value,
  required,
  onChange,
}) => {
  const [selected, setSelected] = useState<"day" | "week" | "month">();

  useEffect(() => {
    if (value?.day) setSelected("day");
    else if (value?.week) setSelected("week");
    else if (value?.month) setSelected("month");
    else setSelected(null);
  }, [value]);

  return (
    <div className={`flex flex-col ${className ?? "mb-4"}`}>
      <label className="text-sm text-gray-500 mb-1 flex justify-start">
        {label}
        {required ? <p className="text-red-600">&nbsp;*</p> : null}
      </label>
      <div
        className={`focus-within:shadow-lg border rounded-md focus-within:border-[#7366f0] transition duration-200 flex overflow-hidden ${className}`}
      >
        <input
          disabled={selected && selected !== "day"}
          className={`appearance-none py-2 px-4 w-1/3 focus:outline-none text-end ${className}`}
          value={!value?.day || value?.day === 0 ? "" : value?.day}
          placeholder="00"
          type="number"
          onInput={(e: any) =>
            (e.target.value =
              e.target.value.slice(0, 2) > 31 ? 31 : e.target.value.slice(0, 2))
          }
          onChange={(e) =>
            onChange({ ...value, day: parseInt(e.target.value) })
          }
        />
        <p
          className={`${value?.day ? "text-gray-900" : "text-gray-400"} ${
            selected && selected !== "day" && "bg-gray-50 cursor-default"
          } py-2 sm:px-2`}
        >
          days
        </p>
        <input
          disabled={selected && selected !== "week"}
          required={!selected}
          className={`appearance-none py-2 px-4 w-1/3 focus:outline-none text-end ${className}`}
          value={!value?.week || value?.week === 0 ? "" : value?.week}
          placeholder="0"
          type="number"
          onInput={(e: any) =>
            (e.target.value =
              e.target.value.slice(0, 1) > 7 ? 7 : e.target.value.slice(0, 1))
          }
          onChange={(e) =>
            onChange({ ...value, week: parseInt(e.target.value) })
          }
        />
        <p
          className={`${value?.week ? "text-gray-900" : "text-gray-400"} ${
            selected && selected !== "week" && "bg-gray-50 cursor-default"
          } py-2 sm:px-2`}
        >
          weeks
        </p>
        <input
          disabled={selected && selected !== "month"}
          className={`appearance-none py-2 px-4 w-1/3 focus:outline-none text-end ${className}`}
          value={!value?.month || value?.month === 0 ? "" : value?.month}
          placeholder="00"
          onInput={(e: any) =>
            (e.target.value =
              e.target.value.slice(0, 2) > 12 ? 12 : e.target.value.slice(0, 2))
          }
          type="number"
          onChange={(e) =>
            onChange({ ...value, month: parseInt(e.target.value) })
          }
        />
        <p
          className={`${value?.month ? "text-gray-900" : "text-gray-400"} ${
            selected && selected !== "month" && "bg-gray-50 cursor-default"
          } py-2 sm:px-2 pr-4`}
        >
          months
        </p>
      </div>
    </div>
  );
};
