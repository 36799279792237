import { FormEvent, useEffect, useState } from "react";
import StorageLayout from "../components/layout/StorageLayout";
import AOS from "aos";
import "aos/dist/aos.css";
import { InputBox } from "../components/items/InputBox";
import { SelectPositionBox } from "../components/items/SelectPsitionBox";
import { InputArea } from "../components/items/InputArea";
import { HelpText } from "../components/items/HelpText";
import {
  deleteTemplate,
  getPreRegister,
  getTemplates,
  updatePreRegister,
  updateTemplates,
} from "../utils";
import { Switch } from "@headlessui/react";
import { SelectSeparator } from "../components/items/SelectSeparator";
import toast from "react-hot-toast";
import { FloatButton } from "../components/items/FloatButton";
import { FiTrash2 } from "react-icons/fi";
import { DurationInput } from "../components/items/DurationInput";
import _ from "lodash";

AOS.init();

const Storage = () => {
  const [data, setData] = useState<any>({ separator: "," }),
    [templates, setTemplates] = useState<any>({}),
    [previewText, setPreviewText] = useState<string[]>([]);

  const handleSave = (e: FormEvent) => {
    e.preventDefault();
    toast.promise(
      updatePreRegister({
        ..._.omit(data, "alert"),
        alert_week: data?.alert?.week ?? 0,
        alert_day: data?.alert?.day ?? 0,
        alert_month: data?.alert?.month ?? 0,
        multiple_brand_separator: data?.multiple_brand_separator.length
          ? data?.multiple_brand_separator
          : ",",
        multiple_zone_separator: data?.multiple_zone_separator.length
          ? data?.multiple_zone_separator
          : ",",
        name: previewText.indexOf("name") + 1,
        zone: previewText.indexOf("zone") + 1,
        brand: previewText.indexOf("brand") + 1,
        mobile: previewText.indexOf("mobile") + 1,
      }),
      {
        loading: "Updating...",
        success: "Data updated sucessfully",
        error: (err) => err.message || "An unexpected error occurred",
      }
    );
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    toast.promise(updateTemplates(templates), {
      loading: "Updating...",
      success: "Templates updated sucessfully",
      error: (err) => err.message || "An unexpected error occurred",
    });
  };

  const addToPreview = (text: string) => {
    if (previewText.length < 4) setPreviewText((prev) => [...prev, text]);
    else
      setPreviewText((prev) => [...prev.filter((txt) => txt !== text), text]);
  };

  useEffect(() => {
    (async () => {
      const data = await getPreRegister();
      if (data) {
        setData({
          ..._.omit(data, ["alert_week", "alert_day", "alert_month"]),
          alert: {
            day: data?.alert_day,
            week: data?.alert_week,
            month: data?.alert_month,
          },
        });
        let tempText = [];
        tempText[data?.name - 1] = "name";
        tempText[data?.zone - 1] = "zone";
        tempText[data?.brand - 1] = "brand";
        tempText[data?.mobile - 1] = "mobile";
        setPreviewText(tempText);
      }
      const templatesData = await getTemplates();
      setTemplates(templatesData);
    })();
  }, []);

  return (
    <StorageLayout showSearch={false}>
      <div className="min-h-[calc(100vh-74px)] px-2 sm:px-5 overflow-auto bg-gray-100">
        <div className="mx-auto w-full sm:w-[90%] md:w-[80%] lg:w-[60%] pb-20">
          <form
            onSubmit={handleSave}
            data-aos="zoom-in"
            className="bg-white rounded-lg shadow my-2 w-full p-5 sm:px-6"
          >
            <div className="sm:flex mb-2 space-x-0  space-y-2 sm:space-y-0 sm:space-x-4">
              <InputBox
                className="sm:w-1/2"
                label="Keyword"
                required
                value={data?.keyword}
                onChange={(e) =>
                  setData((prev: any) => {
                    return { ...prev, keyword: e };
                  })
                }
              />
              <InputBox
                className="sm:w-1/2"
                label="SMS sender ID"
                required
                value={data?.sms_sender_id}
                onChange={(e) =>
                  setData((prev: any) => {
                    return { ...prev, sms_sender_id: e };
                  })
                }
              />
            </div>
            <DurationInput
              className="sm:w-full"
              label="Subscription Pre-notification Alert"
              required
              value={data?.alert}
              onChange={(e) =>
                setData((prev: any) => {
                  return { ...prev, alert: e };
                })
              }
            />
            <div className="sm:flex mt-2 space-x-0  space-y-2 sm:space-y-0 sm:space-x-4">
              <InputBox
                className="sm:w-1/2"
                label="Farmer Short Code"
                required
                value={data?.farmer_short_code}
                onChange={(e) =>
                  setData((prev: any) => {
                    return { ...prev, farmer_short_code: e };
                  })
                }
              />
              <InputBox
                className="sm:w-1/2"
                label="User Short Code"
                required
                value={data?.user_short_code}
                onChange={(e) =>
                  setData((prev: any) => {
                    return { ...prev, user_short_code: e };
                  })
                }
              />
            </div>
            <p className=" text-gray-500 text-sm mt-4">
              Select the items below in desired order
            </p>
            <div className="sm:flex text-gray-500 sm:space-x-3 mt-2">
              <div className="flex space-x-3 w-full mb-4">
                <SelectPositionBox
                  label="name"
                  previewText={previewText}
                  onClick={(e) => addToPreview(e)}
                />
                <SelectPositionBox
                  label="brand"
                  previewText={previewText}
                  onClick={(e) => addToPreview(e)}
                />
              </div>
              <div className="flex space-x-3 w-full mb-4">
                <SelectPositionBox
                  label="zone"
                  previewText={previewText}
                  onClick={(e) => addToPreview(e)}
                />

                <SelectPositionBox
                  label="mobile"
                  previewText={previewText}
                  onClick={(e) => addToPreview(e)}
                />
              </div>
            </div>
            {previewText.length === 4 && (
              <div className="flex flex-col">
                <p className=" text-gray-500 text-sm">Select the separator</p>
                <SelectSeparator
                  separator={data?.separator}
                  onChange={(e) =>
                    setData((prev: any) => {
                      return { ...prev, separator: e };
                    })
                  }
                />
                <p className=" text-gray-500 text-center text-sm mt-4">
                  Preview
                </p>
                <div className="flex border-2 justify-center text-gray-700 border-dotted rounded-md mt-2 px-4 py-2 mb-3">
                  <p className="font-bold mr-2">
                    {data?.keyword?.length ? data?.keyword : "keyword"}
                  </p>
                  {previewText.join(
                    data?.separator === "|"
                      ? " | "
                      : data?.separator === "," || data?.separator === ";"
                      ? `${data?.separator} `
                      : data?.separator
                  )}
                </div>
                <div className="flex justify-between">
                  <label className="text-md text-gray-500 flex">
                    Add multiple Brands :
                  </label>
                  <Switch
                    checked={data?.is_multiple_brand}
                    onChange={(e: boolean) =>
                      setData((prev: any) => {
                        return { ...prev, is_multiple_brand: e };
                      })
                    }
                    className={`${
                      data?.is_multiple_brand ? "bg-purple-600" : "bg-gray-300"
                    } relative inline-flex h-[24px] w-[52px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                    <span
                      aria-hidden="true"
                      className={`${
                        data?.is_multiple_brand
                          ? "translate-x-7"
                          : "translate-x-0"
                      } pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>
                </div>
                {data?.is_multiple_brand && (
                  <div className="mt-2">
                    <InputBox
                      className="sm:w-full"
                      label="Keyword"
                      required
                      value={data?.multiple_brand_keyword}
                      onChange={(e) =>
                        setData((prev: any) => {
                          return { ...prev, multiple_brand_keyword: e };
                        })
                      }
                    />
                    <p className=" text-gray-500 text-sm">
                      Select the brand separator
                    </p>
                    <SelectSeparator
                      separator={
                        data?.multiple_brand_separator.length
                          ? data?.multiple_brand_separator
                          : ","
                      }
                      onChange={(e) =>
                        setData((prev: any) => {
                          return { ...prev, multiple_brand_separator: e };
                        })
                      }
                    />
                    <p className=" text-gray-500 text-center text-sm mt-4">
                      Add brands message preview
                    </p>
                    <div className="space-pre border-2 flex justify-center text-gray-700 border-dotted rounded-md mt-2 px-4 py-2 mb-3">
                      <p className="font-bold mr-2">
                        {data?.multiple_brand_keyword.length
                          ? data?.multiple_brand_keyword
                          : "keyword"}
                      </p>
                      {"brand1"}
                      {data?.multiple_brand_separator.length
                        ? data?.multiple_brand_separator
                        : ","}
                      {" brand2"}
                      {data?.multiple_brand_separator.length
                        ? data?.multiple_brand_separator
                        : ","}
                      {" brand3 ..."}
                    </div>
                    <InputArea
                      className="sm:w-full"
                      label="Message template"
                      value={data?.multiple_brand_message_template ?? ""}
                      required
                      onChange={(e) =>
                        setData((prev: any) => {
                          return {
                            ...prev,
                            multiple_brand_message_template: e,
                          };
                        })
                      }
                    />
                  </div>
                )}
                 <div className="flex justify-between my-2">
                  <label className="text-md text-gray-500 flex">
                    Add multiple Zones :
                  </label>
                  <Switch
                    checked={data?.is_multiple_zone}
                    onChange={(e: boolean) =>
                      setData((prev: any) => {
                        return { ...prev, is_multiple_zone: e };
                      })
                    }
                    className={`${
                      data?.is_multiple_zone ? "bg-purple-600" : "bg-gray-300"
                    } relative inline-flex h-[24px] w-[52px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                    <span
                      aria-hidden="true"
                      className={`${
                        data?.is_multiple_zone
                          ? "translate-x-7"
                          : "translate-x-0"
                      } pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>
                </div>
                {data?.is_multiple_zone && (
                  <div>
                    <InputBox
                      className="sm:w-full"
                      label="Keyword"
                      required
                      value={data?.multiple_brand_keyword}
                      onChange={(e) =>
                        setData((prev: any) => {
                          return { ...prev, multiple_brand_keyword: e };
                        })
                      }
                    />
                    <p className="text-gray-500 text-sm">
                      Select zone separator
                    </p>
                    <SelectSeparator
                      separator={
                        data?.multiple_zone_separator.length
                          ? data?.multiple_zone_separator
                          : ","
                      }
                      onChange={(e) =>
                        setData((prev: any) => {
                          return { ...prev, multiple_zone_separator: e };
                        })
                      }
                    />
                    <p className=" text-gray-500 text-center text-sm mt-4">
                      Add zones message preview
                    </p>
                    <div className="space-pre border-2 flex justify-center text-gray-700 border-dotted rounded-md mt-2 px-4 py-2 mb-3">
                      <p className="font-bold mr-2">
                        {data?.multiple_brand_keyword.length
                          ? data?.multiple_brand_keyword
                          : "keyword"}
                      </p>
                      {"zone1"}
                      {data?.multiple_zone_separator.length
                        ? data?.multiple_zone_separator
                        : ","}
                      {"zone2"}
                      {data?.multiple_zone_separator.length
                        ? data?.multiple_zone_separator
                        : ","}
                      {"zone3 ..."}
                    </div>
                  </div>
                )}

                <button className="px-5 py-1.5 mt-5 text-sm ml-auto bg-purple-600 rounded-lg font-semibold text-white transition-all duration-300 hover:-translate-y-0.5 hover:shadow-lg">
                  Save
                </button>
              </div>
            )}
          </form>
          <form onSubmit={handleSubmit} className="flex flex-col">
            <div
              data-aos="zoom-in"
              className="bg-white rounded-lg shadow my-2 w-full p-5 sm:px-6"
            >
              <div className="text-2xl text-center font-semibold mb-2 w-fit mx-auto">
                Farmer Preregistration Message templates
                <div className="w-[90%] mx-auto h-1 rounded-full bg-purple-600" />
              </div>

              <InputArea
                className="sm:w-full"
                label="Confirmation"
                value={templates?.["preregister-confirmation"]}
                required
                onChange={(e) =>
                  setTemplates((prev: any) => {
                    return { ...prev, "preregister-confirmation": e };
                  })
                }
              />
              <InputArea
                value={templates?.["preregister-completion"]}
                className="sm:w-full"
                label="Completion"
                required
                onChange={(e) =>
                  setTemplates((prev: any) => {
                    return { ...prev, "preregister-completion": e };
                  })
                }
              />
              <InputArea
                value={templates?.["preregister-subscription-alert"]}
                className="sm:w-full"
                label="Subscription Pre-notification Alert"
                required
                onChange={(e) =>
                  setTemplates((prev: any) => {
                    return { ...prev, "preregister-subscription-alert": e };
                  })
                }
              />
              <InputArea
                value={templates?.["preregister-error"]}
                className="sm:w-full"
                label="Error"
                required
                onChange={(e) =>
                  setTemplates((prev: any) => {
                    return { ...prev, "preregister-error": e };
                  })
                }
              />
              <HelpText
                helpText={
                  "Use following placeholders:\n#name# for name\n#brand# for brand\n#zone# for zone\ne.g.\ntemplate : Hello #name# welcome onboard\noutput : Hello Daniel welcome onboard"
                }
              />
            </div>
            <div
              data-aos="zoom-in"
              className="bg-white rounded-lg shadow my-2 w-full p-5 sm:px-6"
            >
              <div className="text-2xl text-center font-semibold mb-2 w-fit mx-auto">
                User Message Templates
                <div className="w-[90%] mx-auto h-1 rounded-full bg-purple-600" />
              </div>
              <InputArea
                className="sm:w-full"
                label="Confirmation"
                value={templates?.["user-alert-confirmation"]}
                required
                onChange={(e) =>
                  setTemplates((prev: any) => {
                    return { ...prev, "user-alert-confirmation": e };
                  })
                }
              />
              <InputArea
                className="sm:w-full"
                label="Farmer Alert"
                value={templates?.["user-farmer-alert-message"]}
                required
                onChange={(e) =>
                  setTemplates((prev: any) => {
                    return { ...prev, "user-farmer-alert-message": e };
                  })
                }
              />
              <InputArea
                className="sm:w-full"
                label="Brand Not Found"
                value={templates?.["user-alert-not-found"]}
                required
                onChange={(e) =>
                  setTemplates((prev: any) => {
                    return { ...prev, "user-alert-not-found": e };
                  })
                }
              />
              <InputArea
                className="sm:w-full"
                label="Limit Reached"
                value={templates?.["user-limit-reached"]}
                required
                onChange={(e) =>
                  setTemplates((prev: any) => {
                    return { ...prev, "user-limit-reached": e };
                  })
                }
              />
              <InputArea
                className="sm:w-full"
                label="Error"
                value={templates?.["user-alert-error"]}
                required
                onChange={(e) =>
                  setTemplates((prev: any) => {
                    return { ...prev, "user-alert-error": e };
                  })
                }
              />
              <HelpText
                helpText={
                  "Use following placeholders:\n#name# for name\n#brand# for brand\n#zone# for zone\ne.g.\ntemplate : Hello #name# welcome onboard\noutput : Hello Daniel welcome onboard"
                }
              />
            </div>
            <div
              data-aos="zoom-in"
              className="bg-white rounded-lg shadow my-2 w-full p-5 sm:px-6"
            >
              <div className="text-2xl text-center font-semibold mb-2 w-fit mx-auto">
                Farmer Enquiry Message Templates
                <div className="w-[90%] mx-auto h-1 rounded-full bg-purple-600" />
              </div>

              <InputArea
                className="sm:w-full"
                label="Enquiry"
                value={templates?.["farmer-enquiry-message"]}
                required
                onChange={(e) =>
                  setTemplates((prev: any) => {
                    return { ...prev, "farmer-enquiry-message": e };
                  })
                }
              />
              <InputArea
                className="sm:w-full"
                label="Error"
                value={templates?.["farmer-enquiry-error"]}
                required
                onChange={(e) =>
                  setTemplates((prev: any) => {
                    return { ...prev, "farmer-enquiry-error": e };
                  })
                }
              />
              <HelpText
                helpText={
                  "Use following placeholders:\n#name# for name\n#brand# for brand\n#zone# for zone\ne.g.\ntemplate : Hello #name# welcome onboard\noutput : Hello Daniel welcome onboard"
                }
              />
            </div>
            <div
              data-aos="zoom-in"
              className="bg-white rounded-lg shadow my-2 w-full p-5 pb-12 sm:px-6"
            >
              <div className="text-2xl text-center font-semibold mb-2 w-fit mx-auto">
                Broadcast Message Templates
                <div className="w-[90%] mx-auto h-1 rounded-full bg-purple-600" />
              </div>
              <HelpText
                helpText={
                  "Use following placeholders:\n#name# for name\n#brand# for brand\n#zone# for zone\ne.g.\ntemplate : Hello #name# welcome onboard\noutput : Hello Daniel welcome onboard"
                }
              />
              {templates?.broadcast_templates?.length ? (
                templates?.broadcast_templates.map((template, key) => {
                  return (
                    <div key={key} className="relative group">
                      <input
                        className="appearance-none px-1 focus:outline-1 focus:outline-gray-400 w-fit text-sm text-gray-500 mb-1"
                        value={template?.label}
                        onChange={(e) => {
                          let tempTemplates = templates?.broadcast_templates;
                          if (!template?.id)
                            tempTemplates[key] = {
                              label: e.target.value,
                              template_message: template?.template_message,
                            };
                          else
                            tempTemplates[key] = {
                              id: template.id,
                              label: e.target.value,
                              template_message: template?.template_message,
                            };
                          setTemplates((prev: any) => {
                            return {
                              ...prev,
                              broadcast_templates: tempTemplates,
                            };
                          });
                        }}
                      />
                      <InputArea
                        className="sm:w-full"
                        value={template?.template_message}
                        required
                        onChange={(e) => {
                          let tempTemplates = templates?.broadcast_templates;
                          if (!template?.id)
                            tempTemplates[key] = {
                              label: template?.label,
                              template_message: e,
                            };
                          else
                            tempTemplates[key] = {
                              id: template.id,
                              label: template?.label,
                              template_message: e,
                            };
                          setTemplates((prev: any) => {
                            return {
                              ...prev,
                              broadcast_templates: tempTemplates,
                            };
                          });
                        }}
                      />
                      <div
                        onClick={() => {
                          setTemplates((prev: any) => {
                            return {
                              ...prev,
                              broadcast_templates:
                                templates?.broadcast_templates.filter(
                                  (t) => t !== template
                                ),
                            };
                          });
                          if (template?.id)
                            toast.promise(deleteTemplate(template.id), {
                              loading: "Deleting...",
                              success: "Template deleted sucessfully",
                              error: (err) =>
                                err.message || "An unexpected error occurred",
                            });
                        }}
                        className="absolute invisible opacity-0 group-hover:opacity-100 group-hover:visible top-4 -right-2 bg-white text-purple-600 text-lg rounded-full shadow p-1.5 hover:text-white hover:bg-purple-600 cursor-pointer transition-all duration-300 ease-in"
                      >
                        <FiTrash2 />
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="text-center text-lg mt-5 font-bold text-gray-400">
                  No Template Added
                </p>
              )}
              <FloatButton
                position="absolute right-5 bottom-3"
                label="Add Template"
                onClick={() =>
                  templates?.broadcast_templates
                    ? setTemplates((prev: any) => {
                        return {
                          ...prev,
                          broadcast_templates: [
                            ...templates?.broadcast_templates,
                            { label: "Edit Label", template_message: "" },
                          ],
                        };
                      })
                    : setTemplates((prev: any) => {
                        return {
                          ...prev,
                          broadcast_templates: [
                            { label: "Edit-Label", template_message: "" },
                          ],
                        };
                      })
                }
              />
            </div>
            <div className="mb-4 self-end">
              <button className="px-5 py-2 bg-purple-600 rounded-lg font-semibold text-white transition-all duration-300 hover:-translate-y-0.5 hover:shadow-lg">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </StorageLayout>
  );
};

export default Storage;
