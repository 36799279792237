import { FormEvent, useEffect, useRef, useState } from "react";
import { TableBuilder } from "../components/items/TableBuilder";
import StorageLayout from "../components/layout/StorageLayout";
import AOS from "aos";
import "aos/dist/aos.css";
import { FloatButton } from "../components/items/FloatButton";
import { exportTable, getUsers, updateUser } from "../utils";
import LoadingBar from "react-top-loading-bar";
import { InputBox } from "../components/items/InputBox";
import toast from "react-hot-toast";
import dayjs from "dayjs";
AOS.init();

export const Users = () => {
  const [search, setSearch] = useState(""),
    [data, setData] = useState<any>({}),
    [loading, setLoading] = useState(true),
    [addUser, setAddUser] = useState(false),
    [userData, setUserData] = useState<any>({ is_active: true }),
    progressRef = useRef<any>(null);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    toast.promise(updateUser(userData), {
      loading: "Adding...",
      success: "User added sucessfully",
      error: (err) => err.message || "An unexpected error occurred",
    });
    setAddUser(false);
    setData((prev) => {
      return [...prev, { ...userData, created_at: dayjs().toISOString() }];
    });
    setUserData({ is_active: true });
  };

  useEffect(() => {
    setLoading(true);
    setData(null);
    (async () => {
      let tempData = await getUsers(1, search);
      setData(tempData);
      setLoading(false);
    })();
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    (async () => {
      progressRef.current.continuousStart();
      let tempData = await getUsers();
      setData(tempData);
      setLoading(false);
      progressRef.current.complete();
    })();
  }, []);
  return (
    <StorageLayout showSearch={true} setSearch={setSearch}>
      <LoadingBar
        color="rgb(147 51 234)"
        height={2}
        ref={progressRef}
        waitingTime={200}
      />
      <div className="min-h-[calc(100vh-80px)] bg-gray-100 px-5">
        <div data-aos="zoom-in" className="flex justify-between pt-4 -mb-2">
          <h2 className="text-3xl font-bold mb-2">Users</h2>
          {addUser ? (
            <form onSubmit={handleSubmit} className="flex items-end space-x-3">
              <InputBox
                className="sm:w-1/2"
                required
                placeholder="Name"
                value={userData?.name}
                onChange={(e) =>
                  setUserData((prev: any) => {
                    return { ...prev, name: e };
                  })
                }
              />
              <InputBox
                className="sm:w-1/2"
                required
                placeholder="Email"
                value={userData?.email}
                onChange={(e) =>
                  setUserData((prev: any) => {
                    return { ...prev, email: e };
                  })
                }
              />
              <button className="px-5 py-2 mb-0.5 h-fit ml-auto bg-purple-600 rounded-lg font-semibold text-white transition-all duration-300 hover:-translate-y-0.5 hover:shadow-lg">
                Save
              </button>
            </form>
          ) : (
            <FloatButton
              position="absolute right-0 top-5"
              label="Add User"
              onClick={() => setAddUser(true)}
            />
          )}
        </div>
        {!loading ? (
          <TableBuilder
            table="users"
            incoming_data={{ users: data }}
            headings={["name", "email", "created_at", "status"]}
          />
        ) : (
          <div
            data-aos="zoom-in"
            className="mx-auto w-fit text-xl font-bold mt-4"
          >
            <img
              className="h-24 rounded-3xl"
              src={
                "https://media.tenor.com/QXPDk3LKgWQAAAAi/cow-dancing-cow.gif"
              }
              alt="loading..."
            />
          </div>
        )}
        <FloatButton
          position="fixed right-5 bottom-5"
          label="Export"
          onClick={() => exportTable("broadcast")}
        />
      </div>
    </StorageLayout>
  );
};
