export const StatusChip = (props: { label: string }) => {
	const getColor = (label: string) => {
		switch (label) {
			case "deleted":
			case "deactivated":
				return "bg-red-500";
			case "active":
			case "delivered":
			case "sent":
				return "bg-green-600";
			case "scheduled":
				return "bg-[#f5a82c]";
			case "pending":
				return "bg-blue-600";
			default:
				return "bg-purple-600";
		}
	};
	return (
		<span
			className={`${getColor(
				props.label
			)} text-white my-1 py-0.5 px-[7px] rounded-md w-fit text-xs font-bold cursor-default uppercase`}
		>
			{props.label}
		</span>
	);
};
