import { Fragment, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { HiCheck } from "react-icons/hi";
import { HiChevronDown } from "react-icons/hi";

interface Props {
  className?: string;
  selectFor?: string;
  value?: { key: string | number; label: string };
  label?: string;
  items: { key: string | number; label: string }[];
  required?: boolean;
  onChange: (arg: { key: string | number; label: string }) => void;
}

export const SelectInput: React.FC<Props> = ({
  className,
  label,
  required,
  value,
  items,
  onChange,
}) => {
  useEffect(() => {
    onChange(value);
    // eslint-disable-next-line
  }, []);
  return (
    <div className={`flex flex-col ${className}`}>
      <label className="text-sm text-gray-500 flex justify-start">
        {label}
        {required ? <p className="text-red-600">&nbsp;*</p> : null}
      </label>
      {items.length ? (
        <Listbox value={value} onChange={onChange}>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full border cursor-pointer rounded-md bg-white focus:outline-none focus:shadow-lg focus:border-purple-600 transition duration-200 py-2 pl-3 pr-10 text-left">
              <span className="block truncate">{value?.label}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <HiChevronDown
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none z-10">
                {items.map((item, itemId) => (
                  <Listbox.Option
                    key={itemId}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pr-10 pl-4 ${
                        active
                          ? "bg-[#f4e7ff74] text-purple-600"
                          : "text-gray-900"
                      }`
                    }
                    value={item}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-semibold" : "font-normal"
                          }`}
                        >
                          {item?.label}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-purple-600">
                            <HiCheck className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      ) : (
        <div className="text-gray-400 mt-1 text-center py-2 rounded-md border">
          Loading...
        </div>
      )}
    </div>
  );
};
