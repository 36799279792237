import { useEffect, useState } from "react";

interface Props {
	className?: string;
	label: string;
	color?: string;
	type?: "button" | "submit" | "reset";
	onClick: (arg?: any) => void;
	disabled?: boolean;
}

export const Button: React.FC<Props> = ({
	className,
	label,
	type,
	color,
	onClick,
	disabled,
}) => {
	const [buttonColor, setButtonColor] = useState("");
	useEffect(() => {
		setButtonColor(`bg-${color ?? "purple"}-600`);
	}, [color]);
	return (
		<button
			disabled={disabled}
			type={type ?? "submit"}
			className={
				`${className ?? "w-fit"} border cursor-pointer
				 whitespace-nowrap rounded-lg text-white font-semibold py-2 px-4 ` + buttonColor
			}
			onClick={() => onClick()}
		>
			{label}
		</button>
	);
};
