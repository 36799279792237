interface Props {
  className?: string;
  required?: boolean;
  label: string;
  placeholder?: string;
  value?: boolean;
  onChange: (e: boolean) => void;
}

export const Check: React.FC<Props> = ({
  className,
  label,
  required,
  value,
  onChange,
}) => {
  return (
    <div className={className}>
      <input
        checked={value}
        required={required}
        className="bg-white appearance-none h-[18px] w-[18px] border border-gray-300 rounded font-light checked:bg-purple-600 checked:border-purple-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
        type={"checkbox"}
        onChange={(e) => onChange(e.target.checked)}
      />
      {label && <label className="inline-block text-gray-500">{label}</label>}
    </div>
  );
};
