import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AccessDenied } from "./pages/401";
import { AddBroadcast } from "./pages/AddBroadcast";
import { Broadcast } from "./pages/Braodcast";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Storage from "./pages/Storage";
import { Users } from "./pages/Users";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/users" element={<Users />} />
        <Route path="/401" element={<AccessDenied />} />
        <Route path="/storage" element={<Storage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/broadcast" element={<Broadcast />} />
        <Route path="/add_broadcast" element={<AddBroadcast />} />
        <Route path="/*" element={<Login />} />
      </Routes>
      <Toaster />
    </BrowserRouter>
  );
}

export default App;
