import { FiX } from "react-icons/fi";

export const Chip = (props: { label: string; onClose: () => void }) => {
  return (
    <div className="bg-purple-600 text-white py-1 px-1.5 rounded min-w-fit text-sm flex items-center cursor-default">
      {props.label}
      <span
        onClick={() => props.onClose()}
        className="ml-2 hover:cursor-pointer"
      >
        <FiX />
      </span>
    </div>
  );
};
