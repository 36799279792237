export const TypeChip = (props: { label: string }) => {
  const getColor = (label: string) => {
    switch (label) {
      case "recurring":
        return "text-green-700 bg-green-100";
      case "scheduled":
        return "text-[#f5a82c] bg-[#ffefd6] ";
      default:
        return "text-[#7366f0] bg-[#eeedfd]";
    }
  };
  return (
    <div
      className={`${getColor(
        props.label
      )} text-white py-0.5 my-1 px-[7px]  rounded-md w-fit text-xs font-bold cursor-default uppercase`}
    >
      {props.label}
    </div>
  );
};
