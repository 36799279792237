import dayjs from "dayjs";
import _ from "lodash";
import { FormEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { BsArrowLeftCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Check } from "../components/items/Check";
import { DateInput } from "../components/items/DateInput";
import { InputBox } from "../components/items/InputBox";
import { MessageField } from "../components/items/MessageField";
import { SelectBroadcastTo } from "../components/items/SelectBroadcastTo";
import { SelectInput } from "../components/items/SelectInput";
import { TimeInput } from "../components/items/TimeInput";
import StorageLayout from "../components/layout/StorageLayout";
import { addBroadcast, getTemplates, getZones } from "../utils";

export const AddBroadcast = () => {
  const [templates, setTemplates] = useState<any>([]),
    [options, setOptions] = useState<any>([]),
    [zoneOptions, setZoneOptions] = useState<any>([]),
    [formData, setFormData] = useState<any>({
      campaign_name: "",
      campaign_type: "normal",
      to: {
        value: "others",
        custom: [""],
      },
      zone: "",
    }),
    navigate = useNavigate();

  const handleSave = async (e: FormEvent) => {
    e.preventDefault();
    let data = _.pick(formData, [
      "campaign_name",
      "msg",
      "campaign_type",
      "zone",
    ]);
    data["to"] = [
      formData.to.value,
      ...formData.to.custom.filter((str) => str.length > 0),
    ];

    if (data.campaign_type === "scheduled")
      data["scheduled_on"] =
        formData?.schedule_date.substring(0, 10) +
        formData?.schedule_time.substring(10);

    toast.promise(
      addBroadcast({
        ...data,
        to: data["to"].filter((str) => str !== "others"),
      }),

      {
        loading: "Adding...",
        success: "Broadcast Added sucessfully",
        error: (err) => err.message || "An unexpected error occurred",
      }
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let tempData = await getTemplates();
        let zoneData = await getZones();

        setTemplates(tempData.broadcast_templates);
        setFormData((prev) => ({
          ...prev,
          msg: tempData.broadcast_templates?.[0]?.template_message,
        }));
        const convertedData = zoneData.data.map((item, index) => ({
          key: index,
          label: item,
        }));
        setZoneOptions(convertedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!templates.length) return;
    let tempOptions = [{}];
    templates.forEach((template, key) => {
      tempOptions[key] = { key: template.id, label: template.label };
    });
    setOptions(tempOptions);
  }, [templates]);

  return (
    <StorageLayout showSearch={false}>
      <div className="min-h-[calc(100vh-74px)] px-2 sm:px-5 overflow-auto bg-gray-100">
        <div className="mx-auto w-full sm:w-[90%] md:w-[80%] lg:w-[60%] pb-20">
          <form
            onSubmit={handleSave}
            data-aos="zoom-in"
            className="bg-white relative rounded-lg shadow my-2 w-full p-5 sm:px-6"
          >
            <SelectBroadcastTo
              className="mb-4"
              label={"To"}
              selected={
                formData?.to ?? {
                  value: "others",
                  custom: [""],
                }
              }
              onChange={(e) =>
                setFormData((prev) => {
                  return { ...prev, to: e };
                })
              }
            />
            {zoneOptions.length > 0 && formData?.to.value === "zones" && (
              <SelectInput
                className="sm:w-1/2 mb-8"
                label={"Zones"}
                value={
                  formData?.zone
                    ? zoneOptions.find(
                        (option) => option.label === formData?.zone
                      )
                    : zoneOptions[0]
                }
                items={zoneOptions}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, zone: e?.label }))
                }
              />
            )}

            <div className="sm:flex mt-2 space-x-0  space-y-2 sm:space-y-0 sm:space-x-4 mb-4">
              <InputBox
                className="sm:w-1/2"
                label="Name"
                required
                value={formData?.campaign_name}
                onChange={(e) =>
                  setFormData((prev: any) => {
                    return { ...prev, campaign_name: e };
                  })
                }
              />
              <SelectInput
                className="sm:w-1/2"
                label={"Template"}
                value={
                  formData?.sms_template
                    ? options.find(
                        (option) => option.key === formData?.sms_template
                      )
                    : options[0]
                }
                items={options}
                onChange={(e) =>
                  setFormData((prev) => {
                    return {
                      ...prev,
                      sms_template: e?.key,
                      msg: templates.find((template) => template.id === e?.key)
                        ?.template_message,
                    };
                  })
                }
              />
            </div>

            <MessageField
              message={formData?.msg ?? ""}
              onChange={(e) =>
                setFormData((prev) => {
                  return { ...prev, msg: e };
                })
              }
            />
            <Check
              label={"Schedule campaign?"}
              value={formData?.campaign_type === "scheduled"}
              onChange={(e) =>
                setFormData((prev) => {
                  return {
                    ...prev,
                    campaign_type: e ? "scheduled" : "normal",
                  };
                })
              }
            />
            <p className="font-light text-sm text-[#9333eab0] ml-6 mb-4">
              Set a specific date, time, and frequency for your campaign
            </p>
            {formData?.campaign_type === "scheduled" ? (
              <div className="md:flex justify-between">
                <DateInput
                  className="md:w-[calc(50%-10px)]"
                  label={"Date"}
                  required
                  value={formData?.schedule_date}
                  onChange={(e) =>
                    setFormData((prev) => {
                      return {
                        ...prev,
                        schedule_date: dayjs(e).toISOString(),
                      };
                    })
                  }
                />
                <TimeInput
                  className="md:w-[calc(50%-10px)]"
                  label={"Time"}
                  value={formData?.schedule_time ?? new Date()}
                  onChange={(e) =>
                    setFormData((prev) => {
                      return {
                        ...prev,
                        schedule_time: dayjs(e).toISOString(),
                      };
                    })
                  }
                />
              </div>
            ) : null}
            <div
              onClick={() => navigate("/broadcast")}
              className={`absolute top-2 -left-10 flex items-center space-x-2 bg-white rounded-full p-1 hover:-translate-y-0.5 hover:shadow-md cursor-pointer group z-30 transition-all duration-100`}
            >
              <BsArrowLeftCircle
                data-aos="zoom-in"
                className="text-purple-600 text-2xl"
              />
            </div>
            <div className="flex">
              <button className="ml-auto px-5 py-2 bg-purple-600 rounded-lg font-semibold text-white transition-all duration-300 hover:-translate-y-0.5 hover:shadow-lg">
                {formData?.campaign_type === "scheduled"
                  ? "Schedule"
                  : "Send Now"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </StorageLayout>
  );
};
