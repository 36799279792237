interface Props {
  className?: string;
  previewText: string[];
  label: string;
  onClick: (str: string) => void;
}

export const SelectPositionBox: React.FC<Props> = ({
  className,
  previewText,
  label,
  onClick,
}) => {
  return (
    <div
      onClick={() => onClick(label)}
      className={`w-1/2 flex justify-between rounded-md border py-2 px-3 items-center group hover:shadow-md hover:border-purple-600 hover:text-purple-600 transition-all duration-200 ${
        previewText.includes(label) && "border-purple-600 text-purple-600"
      }`}
    >
      <p>{label}</p>
      {previewText.includes(label) ? (
        <p className="transition-all duration-200">
          {previewText.indexOf(label) + 1}
        </p>
      ) : (
        <div className="rounded-full h-5 w-5 border group-hover:bg-purple-600 transition-all duration-200" />
      )}
    </div>
  );
};
