interface Props {
  className?: string;
  placeholder?: string;
  value?: string;
  label?: string;
  required?: boolean;
  onChange: (str: string) => void;
}

export const InputBox: React.FC<Props> = ({
  className,
  label,
  required,
  placeholder,
  value,
  onChange,
}) => {
  return (
    <div className={`flex flex-col ${className ?? "mb-4"}`}>
      {label && label.length && (
        <label className="text-sm text-gray-500 mb-1 flex justify-start">
          {label}
          {required ? <p className="text-red-600">&nbsp;*</p> : null}
        </label>
      )}
      <span>
        <input
          required={required}
          className="border rounded-md py-2 px-4 w-full focus:outline-none focus:shadow-lg focus:border-purple-600 transition duration-200"
          value={value}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
        />
      </span>
    </div>
  );
};
