import { InputArea } from "./InputArea";

interface Props {
  message?: string;
  onChange: (e: string) => void;
}

export const MessageField: React.FC<Props> = ({ message, onChange }) => {
  return (
    <>
      <InputArea
        label={"Message"}
        required={true}
        value={message}
        onChange={(e) => onChange(e)}
      />
      <div className="flex justify-between -mt-4 mb-4">
        <p className="font-light text-sm text-[#9333eab0]">{`${
          message.length % 160
            ? 160 - (message.length % 160)
            : message.length
            ? 0
            : 160
        } CHARACTERS REMAINING`}</p>
        <p className="font-light text-sm text-[#9333eab0]">{`${
          message.length / 160 > Math.floor(message.length / 160) ||
          !message.length
            ? 1 + Math.floor(message.length / 160)
            : message.length / 160
        } MESSAGE(S)`}</p>
      </div>
    </>
  );
};
